import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { Badge } from 'flowbite-react';
import StripeAmount from '../../../utils/StripeAmount';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { CreditCard, Settings } from 'react-feather';

const Desktop = ({ data }) => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  //stuff for pagination
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 8;

  const endOffset = itemOffset + itemsPerPage;

  const currentPayouts = data?.payouts.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data?.payouts.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data?.payouts?.length;
    setItemOffset(newOffset);
  };
  //end of pagination stuff

  const ElapsedTimeOrDate = ({ payoutDate }) => {
    const now = new Date();
    const duration = moment.duration(moment(now).diff(moment(payoutDate)));
    const secondsElapsed = duration.asSeconds();
    const minutesElapsed = duration.asMinutes();
    const hoursElapsed = duration.asHours();
    const daysElapsed = duration.asDays();

    let displayText = '';

    if (secondsElapsed < 60) {
      displayText = `${Math.floor(secondsElapsed)} seconds ago`;
    } else if (minutesElapsed < 60) {
      displayText =
        Math.floor(minutesElapsed) === 1
          ? `${Math.floor(minutesElapsed)} minute ago`
          : `${Math.floor(minutesElapsed)} minutes ago`;
    } else if (hoursElapsed < 24) {
      displayText =
        Math.floor(hoursElapsed) === 1
          ? `${Math.floor(hoursElapsed)} hour ago`
          : `${Math.floor(hoursElapsed)} hours ago`;
    } else if (daysElapsed < 3) {
      displayText =
        Math.floor(daysElapsed) === 1
          ? `${Math.floor(daysElapsed)} day ago`
          : `${Math.floor(daysElapsed)} days ago`;
    } else {
      displayText = `${moment(payoutDate).format('MMM D, YYYY')}`;
    }

    return <span>{displayText}</span>;
  };

  const DisplayBadge = ({ status }) => {
    switch (status) {
      case 'paid':
        return (
          <Badge color="success" size="xs">
            Paid
          </Badge>
        );
      case 'failed':
        return (
          <Badge color="failure" size="xs">
            Failed
          </Badge>
        );
      case 'cancelled':
        return (
          <Badge color="failure" size="xs">
            Cancelled
          </Badge>
        );
      case 'pending':
        return (
          <Badge color="warning" size="xs">
            Pending
          </Badge>
        );
      case 'in_transit':
        return (
          <Badge color="warning" size="xs">
            In Transit
          </Badge>
        );
      default:
        break;
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-end justify-between">
        <div className="w-9/12 flex flex-col items-start border border-gray-200 rounded-md p-4 relative">
          {!currentUser?.bankAdded &&
            !currentUser?.stripeOnboard &&
            !currentUser?.bankPending &&
            !currentUser?.stripePending && (
              <div className="absolute right-0 top-0 mr-2 mt-2">
                <Badge color="failure" size="xs">
                  Payouts Disabled
                </Badge>
              </div>
            )}
          {currentUser?.bankAdded && (
            <div className="absolute right-0 top-0 mr-2 mt-2">
              <Badge color="success" size="xs">
                Payouts Enabled
              </Badge>
            </div>
          )}
          {currentUser?.stripeOnboard && (
            <div className="absolute right-0 top-0 mr-2 mt-2">
              <Badge color="success" size="xs">
                Payouts Enabled
              </Badge>
            </div>
          )}
          {currentUser?.bankPending && (
            <div className="absolute right-0 top-0 mr-2 mt-2">
              <Badge color="info" size="xs">
                Bank Pending
              </Badge>
            </div>
          )}
          {currentUser?.stripePending && (
            <div className="absolute right-0 top-0 mr-2 mt-2">
              <Badge color="info" size="xs">
                Stripe Pending
              </Badge>
            </div>
          )}
          {!currentUser?.bankAdded &&
            !currentUser?.stripeOnboard &&
            !currentUser?.bankPending &&
            !currentUser?.stripePending && (
              <div className="flex flex-col gap-1 items-start break-words">
                <p className="text-sm text-stone-800">
                  No payout option connected
                </p>
                <p className="text-stone-600 text-xs">
                  You need to connect a payout option in settings
                </p>
                <Link
                  to="/settings"
                  className="flex items-center justify-center gap-1 bg-gray-200 text-xs text-stone-800 rounded-md p-1 pl-2 pr-2"
                >
                  <Settings size={14} />
                  <p className="text-xs">Settings</p>
                </Link>
              </div>
            )}
          {currentUser?.bankPending && (
            <div className="flex flex-col items-start gap-1 break-words">
              <p className="text-xs text-stone-600">Payouts via bank account</p>
              <p className="text-stone-800 text-xs">
                Continue connecting your bank account in settings
              </p>
              <Link
                to="/settings"
                className="flex items-center justify-center gap-1 bg-gray-200 text-xs text-stone-800 rounded-md p-1 pl-2 pr-2"
              >
                <Settings size={14} />
                <p className="text-xs">Settings</p>
              </Link>
            </div>
          )}
          {currentUser?.stripePending && (
            <div className="flex flex-col break-words">
              <p className="text-xs text-stone-600">
                Payouts via Stripe account
              </p>
              <p className="text-stone-800 text-sm">
                Continue connecting Stripe account in settings
              </p>
            </div>
          )}
          {currentUser?.bankAdded && (
            <div className="flex gap-10">
              <div className="flex flex-col break-words">
                <p className="text-xs text-stone-600">
                  Payouts via bank account
                </p>
                <p className="text-stone-800 text-sm">
                  {currentUser?.bankName}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="text-xs text-stone-800">Balance</p>
                <div className="flex items-center gap-4">
                  <div className="flex flex-col items-start">
                    <p className="text-stone-800 text-sm">
                      <StripeAmount
                        amount={data?.balance?.available}
                        currency={currentUser?.bankCurrency}
                      />
                    </p>
                    <p className="text-xs text-stone-600">Available</p>
                  </div>
                  <div className="flex flex-col items-start">
                    <p className="text-stone-800 text-sm">
                      <StripeAmount
                        amount={data?.balance?.pending}
                        currency={currentUser?.bankCurrency}
                      />
                    </p>
                    <p className="text-xs text-stone-600">Pending</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentUser?.stripeOnboard && (
            <div className="flex flex-col break-words">
              <p className="text-xs text-stone-600">
                Payouts via Stripe account
              </p>
              <p className="text-stone-800 text-sm">{currentUser?.stripeId}</p>
            </div>
          )}
        </div>
        {data?.payouts.length > 0 && (
          <div className="w-3/12 flex justify-end items-center">
            <div className="flex items-center">
              <p className="text-stone-600 text-xs">
                {data?.payouts.length === 1
                  ? `${data?.payouts.length} payment`
                  : `${data?.payouts.length} payments`}
              </p>
            </div>
          </div>
        )}
      </div>

      {data?.payouts.length ? (
        <div className="flex flex-col w-full bg-white gap-2">
          {currentPayouts.map((payout, index) => (
            <Link to={`/dashboard/payouts/${payout?._id}`} key={index}>
              <div className="flex justify-between items-center border border-gray-200 rounded-md p-2 hover:bg-gray-50">
                <div className="flex flex-col items-start gap-1">
                  <p className="text-stone-800 text-xs">{payout?._id}</p>
                  <DisplayBadge status={payout?.status} />
                </div>

                <button
                  disabled
                  className="p-2 bg-gray-200 text-stone-800 text-xs rounded-md"
                >
                  <StripeAmount
                    amount={payout?.amount}
                    currency={payout?.currency}
                  />
                </button>
              </div>
            </Link>
          ))}
          {data?.payouts.length > 8 && (
            <div className="w-full flex justify-end mt-2">
              <div className="">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  marginPagesDisplayed={0}
                  pageRangeDisplayed={0}
                  pageCount={pageCount}
                  previousLabel="Prev"
                  renderOnZeroPageCount={null}
                  className="flex items-center"
                  activeLinkClassName="activePage"
                  pageLinkClassName="notActivePage"
                  breakLinkClassName="breakLink"
                  disabledClassName="disabled"
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="flex flex-col border-gray-200 items-center justify-center rounded-md w-full border bg-white"
          style={{ height: '600px' }}
        >
          <div className="flex flex-col items-center gap-2">
            <CreditCard size={18} className="text-stone-800" />
            <p className="text-stone-800 text-sm">No payouts</p>
            <p className="text-stone-600 text-xs text-center w-60">
              {currentUser?.bankAdded
                ? 'You will receive payouts when you make sales and accumulate a balance'
                : 'You only see payouts here if your payout option is a bank account'}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Desktop;
