import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  useLazyGetOnboardUrlQuery,
  useDisconnectStripeMutation,
  useLazyGetBankUrlQuery,
  useLazyGetUpdateUrlQuery,
  useRemoveBankMutation,
  useChangePayoutScheduleMutation,
} from '../../api/authApiSlice';
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import { BiSolidBank } from 'react-icons/bi';
import { BsStripe } from 'react-icons/bs';
import { toast } from 'react-toastify';

//flowbite
import { Badge, Spinner, Tooltip } from 'flowbite-react';
import {
  ChevronDown,
  ChevronRight,
  ChevronUp,
  Edit,
  Trash,
} from 'react-feather';
import { showNotification } from '../../redux/toastSlice';
import StripeAmount from '../../utils/StripeAmount';

const Payments = ({ refetch }) => {
  const dispatch = useDispatch();

  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const aToken = Cookies.get('aToken');
  const [onboardUrl, setOnboardUrl] = useState('');
  const [gettingUrl, setGettingUrl] = useState(false);
  const [gettingBankLink, setGettingBankLink] = useState(false);
  const [gettingUpdateUrl, setGettingUpdateUrl] = useState(false);
  const [show, setShow] = useState(false);
  const [removeB, setRemoveB] = useState(false);
  const [schedule, setSchedule] = useState(currentUser?.schedule);
  const [changeSched, setChangeSched] = useState(false);

  const [getOnboardUrl, result] = useLazyGetOnboardUrlQuery();
  const [getBankUrl, res] = useLazyGetBankUrlQuery();
  const [getUpdateUrl, resp] = useLazyGetUpdateUrlQuery();

  const [disconnectStripe, { isLoading, isSuccess }] =
    useDisconnectStripeMutation();
  const [removeBank, { isLoading: removing, isSuccess: removed }] =
    useRemoveBankMutation();
  const [changePayoutSchedule, { isLoading: changing, isSuccess: changed }] =
    useChangePayoutScheduleMutation();

  const getStripeUrl = async () => {
    setGettingUrl(true);
    const urlReq = await getOnboardUrl().unwrap();
    window.location.href = urlReq.url;
  };

  const getBankLink = async () => {
    setGettingBankLink(true);
    const urlReq = await getBankUrl().unwrap();
    window.location.href = urlReq.url;
  };

  const getUpdateLink = async () => {
    setGettingUpdateUrl(true);
    const urlReq = await getUpdateUrl().unwrap();
    window.location.href = urlReq.url;
  };

  const handleDisconnectStripe = async () => {
    try {
      const respon = await disconnectStripe().unwrap();

      if (respon === 'Stripe disconnected') {
        refetch();
        dispatch(showNotification('Stripe disconnected'));
      }
    } catch (err) {
      return;
    }
  };

  const handleRemoveBank = async () => {
    try {
      const respon = await removeBank().unwrap();
      if (respon === 'Bank deleted') {
        refetch();
        dispatch(showNotification('Bank disconnected'));
      }
    } catch (err) {
      return;
    }
  };

  const handleChangeSchedule = async () => {
    try {
      const changeReq = await changePayoutSchedule({
        schedule: schedule,
      }).unwrap();
      if (changeReq === 'Schedule changed') {
        refetch();
        setChangeSched(false);
        dispatch(showNotification('Payout schedule updated'));
      }
    } catch (err) {
      return;
    }
  };

  return (
    <div>
      <div className="w-full">
        <div className="flex flex-col items-start p-4 border border-gray-200 rounded-md relative">
          {show ? (
            <button
              type="button"
              className="text-xs bg-gray-200 flex items-center gap-1 text-stone-800 rounded-md p-1 pl-2 pr-2 absolute bottom-0 right-0 mr-2 mb-2"
              onClick={() => setShow(!show)}
            >
              Details <ChevronUp size={14} />
            </button>
          ) : (
            <button
              type="button"
              className="text-xs bg-gray-200 flex items-center gap-1 text-stone-800 rounded-md p-1 pl-2 pr-2 absolute bottom-0 right-0 mr-2 mb-2"
              onClick={() => setShow(!show)}
            >
              Details <ChevronDown size={14} />
            </button>
          )}
          <div className="absolute top-0 right-0 mr-2 mt-2">
            {currentUser?.bankPending && (
              <Badge color="info">Continue with Bank</Badge>
            )}
            {currentUser?.bankAdded && (
              <Badge color="success">Bank Connected</Badge>
            )}
            {currentUser?.stripeOnboard && (
              <Badge color="success">Stripe Connected</Badge>
            )}
            {currentUser?.stripePending && (
              <Badge color="info">Continue with Stripe</Badge>
            )}
          </div>

          <div className="flex items-end gap-16 w-full">
            <div className="flex flex-col gap-1">
              <p className="text-sm text-stone-800">Payments & Payouts</p>
              <p className="text-xs text-stone-600">
                Choose a payout option to connect so you can get paid
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2 w-full mt-4">
            {!currentUser?.stripeOnboard && !currentUser?.stripePending && (
              <div className="flex flex-col gap-2 w-72">
                {(currentUser?.balance && currentUser?.bankPending) ||
                currentUser?.bankAdded ? (
                  <div className="flex flex-col">
                    <p className="text-xs text-stone-800">Balance</p>
                    <div className="flex items-center gap-4">
                      <div className="flex flex-col items-start">
                        <p className="text-stone-800 text-sm">
                          <StripeAmount
                            amount={currentUser?.balanceAmount?.available}
                            currency={currentUser?.bankCurrency}
                          />
                        </p>
                        <p className="text-xs text-stone-600">Available</p>
                      </div>
                      <div className="flex flex-col items-start">
                        <p className="text-stone-800 text-sm">
                          <StripeAmount
                            amount={currentUser?.balanceAmount?.pending}
                            currency={currentUser?.bankCurrency}
                          />
                        </p>
                        <p className="text-xs text-stone-600">Pending</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="border border-gray-200 rounded-md p-2 w-72">
                  {!currentUser?.bankAdded && (
                    <div className="flex flex-col items-start">
                      <div className="flex items-center">
                        <BiSolidBank className="mr-1 text-stone-800" />
                        <p className="text-stone-800 text-sm">Bank</p>
                      </div>
                      <p className="text-xs text-stone-600 mt-1">
                        Be paid out to a bank account
                      </p>
                      {gettingBankLink || gettingUpdateUrl || removing ? (
                        <Spinner className="mt-2" />
                      ) : (
                        <>
                          {currentUser?.bankPending ? (
                            <div className="flex items-center">
                              <button
                                type="button"
                                className="text-xs bg-gray-200 text-stone-800 rounded-md pl-2 pr-2 p-1 mt-1"
                                onClick={getUpdateLink}
                              >
                                Continue
                              </button>
                              {currentUser?.balance ? (
                                <Tooltip
                                  content={
                                    <p className="text-xs text-stone-800 w-72 text-left">
                                      You must have a balance of $0 to remove
                                      your bank account
                                    </p>
                                  }
                                  style="light"
                                  arrow={false}
                                >
                                  <button
                                    type="button"
                                    className="text-xs hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 mt-1 ml-1"
                                    disabled
                                  >
                                    Remove
                                  </button>
                                </Tooltip>
                              ) : (
                                <button
                                  type="button"
                                  className="text-xs hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 mt-1 ml-1"
                                  onClick={handleRemoveBank}
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          ) : (
                            <button
                              type="button"
                              className="text-xs bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 mt-1"
                              onClick={getBankLink}
                              disabled={currentUser?.bankPending}
                            >
                              Connect
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {currentUser?.bankAdded && (
                    <div className="flex flex-col items-start break-words gap-2 relative">
                      <div className="flex flex-col">
                        <p className="text-xs text-stone-600">Bank</p>
                        <p className="text-stone-800 text-xs">
                          {currentUser?.bankName}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-xs text-stone-600">Currency</p>
                        <p className="text-stone-800 text-xs">
                          {currentUser?.bankCurrency}
                        </p>
                      </div>
                      <div className="flex items-center gap-1 absolute right-0 bottom-0">
                        {removeB ? (
                          <>
                            <button
                              type="button"
                              className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                              onClick={handleRemoveBank}
                              disabled={removing}
                            >
                              Remove bank
                            </button>
                            <button
                              type="button"
                              className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                              onClick={(e) => setRemoveB(!removeB)}
                            >
                              Cancel
                            </button>
                          </>
                        ) : (
                          <>
                            {currentUser?.balance ? (
                              <button
                                type="button"
                                className="text-red-400"
                                disabled
                              >
                                <Tooltip
                                  content={
                                    <p className="text-xs text-stone-800 w-72 text-left">
                                      You must have a balance of $0 to remove
                                      your bank account
                                    </p>
                                  }
                                  style="light"
                                  arrow={false}
                                >
                                  <Trash size={14} />
                                </Tooltip>
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="text-red-400"
                                onClick={() => setRemoveB(!removeB)}
                              >
                                <Trash size={14} />
                              </button>
                            )}
                            <button
                              type="button"
                              className="text-stone-800 ml-2"
                              onClick={getUpdateLink}
                            >
                              <Edit size={14} />
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {currentUser?.bankAdded ? (
                  <div className="border border-gray-200 rounded-md p-2 flex items-center justify-between w-72">
                    {changeSched ? (
                      <div className="flex flex-col w-full">
                        <div className="flex items-center justify-between w-full">
                          <p className="text-xs text-stone-600">
                            Change payout schedule
                          </p>
                          <div className="flex items-center gap-1">
                            <button
                              type="button"
                              className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                              onClick={() => setChangeSched(!changeSched)}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                              onClick={handleChangeSchedule}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                        <div className="flex items-center gap-1">
                          {changing ? (
                            <Spinner />
                          ) : (
                            <>
                              <button
                                type="button"
                                className={`${
                                  schedule === 'Weekly' ? 'bg-gray-200' : ''
                                } text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs`}
                                onClick={() => setSchedule('Weekly')}
                              >
                                Weekly
                              </button>
                              <button
                                type="button"
                                className={`${
                                  schedule === 'Monthly' ? 'bg-gray-200' : ''
                                } text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs`}
                                onClick={() => setSchedule('Monthly')}
                              >
                                Monthly
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        <p className="text-xs text-stone-600">
                          Schedule:{' '}
                          <span className="font-bold">
                            {currentUser?.schedule}
                          </span>
                        </p>
                        <button
                          type="button"
                          className="text-stone-800 rounded-md"
                          onClick={() => setChangeSched(!changeSched)}
                        >
                          <Edit size={14} />
                        </button>
                      </>
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}

            {!currentUser?.bankPending && !currentUser?.bankAdded && (
              <div className="relative border border-gray-200 rounded-md p-2 w-72">
                {currentUser?.stripeOnboard ? (
                  <div className="flex flex-col items-start gap-2 break-words relative">
                    <p className="text-xs text-stone-800">
                      Payouts via Stripe account
                    </p>
                    <div className="flex flex-col">
                      <p className="text-xs text-stone-600">Account</p>
                      <p className="text-stone-800 text-sm">
                        {currentUser?.stripeId}
                      </p>
                    </div>

                    <button
                      type="button"
                      className="text-xs bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2"
                      onClick={handleDisconnectStripe}
                      disabled={isLoading}
                    >
                      Disconnect
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-col items-start">
                    <div className="flex items-center">
                      <BsStripe className="mr-1 text-stone-800" />
                      <p className="text-stone-800 text-sm">Stripe</p>
                    </div>
                    <p className="text-xs text-stone-600 mt-1">
                      Be paid out to a Stripe account
                    </p>

                    {gettingUrl || isLoading ? (
                      <Spinner className="mt-2" />
                    ) : (
                      <>
                        {currentUser?.stripePending ? (
                          <div className="flex items-center">
                            <button
                              type="button"
                              className="text-xs bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 mt-1"
                              onClick={getStripeUrl}
                            >
                              Continue
                            </button>
                            <button
                              type="button"
                              className="text-xs hover:bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 mt-1 ml-1"
                              onClick={handleDisconnectStripe}
                            >
                              Remove
                            </button>
                          </div>
                        ) : (
                          <button
                            type="button"
                            className="text-xs bg-gray-200 text-stone-800 rounded-md p-1 pr-2 pl-2 mt-1"
                            onClick={getStripeUrl}
                          >
                            Connect
                          </button>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          {show && (
            <div className="w-full flex flex-col gap-2 items-start mt-4">
              <div className="flex items-start gap-4 w-full">
                <div className="flex flex-col gap-2 w-3/6">
                  <div className="flex items-end">
                    <BiSolidBank className="mr-1 text-stone-800" />
                    <p className="text-stone-800 text-xs">Bank payouts</p>
                  </div>
                  <p className="text-xs text-stone-600">
                    When receiving payouts via bank account, you can choose
                    between monthly(last day of month) or weekly(every Friday)
                    payouts. Payouts occur when you accumulate a balance in your
                    Fruntt account as you make sales.
                  </p>
                </div>
                <div className="flex flex-col gap-2 w-3/6">
                  <div className="flex items-end">
                    <BsStripe className="mr-1 text-stone-800" />
                    <p className="text-stone-800 text-xs">Stripe payouts</p>
                  </div>
                  <p className="text-xs text-stone-600">
                    When receiving payouts via Stripe account, funds are
                    immediately transferred to your account after every
                    successful purchase. You will not accumulate a balance in
                    your Fruntt account, only in your connected Stripe account.
                  </p>
                </div>
              </div>
              <Link
                to="/pricing"
                className="flex items-center justify-center text-xs text-stone-800"
              >
                See pricing <ChevronRight size={14} />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Payments;
