import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactCountryFlag from 'react-country-flag';
import { Rating } from '@mui/material';
import { Badge, Spinner, Timeline, Tooltip } from 'flowbite-react';
import {
  ChevronRight,
  Eye,
  MessageSquare,
  Link as Flink,
  ChevronUp,
} from 'react-feather';
import { useMarkAsSeenMutation } from '../../../api/ordersApiSlice';
import Cookies from 'js-cookie';

const Desktop = ({ order, refetch }) => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const [openRev, setOpenRev] = useState(false);

  const [markAsSeen, { isLoading, isSuccess }] = useMarkAsSeenMutation();

  const version = order?.item?.versions
    ? order?.item?.versions.find((version) => version?._id === order?.versionId)
    : undefined;

  const orderTotal = order?.total || 0; // Ensure order total is defined
  const stripeFeePercentage = orderTotal * 0.029;
  const roundedStripeFee = (
    Math.round(stripeFeePercentage * 100) / 100
  ).toFixed(2);
  const stripeFee = parseFloat(roundedStripeFee) + 0.3;
  const fullStripeFee = stripeFee.toFixed(2);

  // const stripeFee = Math.round(order?.total * 0.029 * 100) / 100;
  // const fullStripeFee = stripeFee + 0.3;
  const taxAmount = order?.tax / 100;
  const IntFee = Math.round(order?.total * 0.015 * 100) / 100;
  const youMakeAfterFrunttLowFee =
    order?.paymentOrigin === 'US'
      ? Math.round((order?.total - fullStripeFee - 0.25) * 100) / 100
      : Math.round((order?.total - fullStripeFee - IntFee - 0.25) * 100) / 100;
  const youMakeAfterFrunttHighFee =
    order?.paymentOrigin === 'US'
      ? Math.round((order?.total - fullStripeFee - 1) * 100) / 100
      : Math.round((order?.total - fullStripeFee - IntFee - 1) * 100) / 100;

  useEffect(() => {
    const markSeen = async () => {
      const seenReq = await markAsSeen({ orderId: order?._id }).unwrap();

      if (seenReq.msg === 'Order seen') {
        currentUser.newOrders = seenReq.newOrders;
        const updatedUser = JSON.stringify(currentUser);
        Cookies.set('currentUser', updatedUser, { sameSite: 'Lax' });
        refetch();
      }
    };

    if (!order?.seen) {
      markSeen();
    }
  }, []);

  const ElapsedTimeOrDate = ({ orderDate }) => {
    const now = new Date();
    const duration = moment.duration(moment(now).diff(moment(orderDate)));
    const secondsElapsed = duration.asSeconds();
    const minutesElapsed = duration.asMinutes();
    const hoursElapsed = duration.asHours();
    const daysElapsed = duration.asDays();

    let displayText = '';

    if (secondsElapsed < 60) {
      displayText = `${Math.floor(secondsElapsed)} seconds ago`;
    } else if (minutesElapsed < 60) {
      displayText =
        Math.floor(minutesElapsed) == 1
          ? `Placed ${Math.floor(minutesElapsed)} minute ago`
          : `Placed ${Math.floor(minutesElapsed)} minutes ago`;
    } else if (hoursElapsed < 24) {
      displayText =
        Math.floor(hoursElapsed) == 1
          ? `Placed ${Math.floor(hoursElapsed)} hour ago`
          : `Placed ${Math.floor(hoursElapsed)} hours ago`;
    } else if (daysElapsed < 3) {
      displayText =
        Math.floor(daysElapsed) == 1
          ? `Placed ${Math.floor(daysElapsed)} day ago`
          : `Placed ${Math.floor(daysElapsed)} days ago`;
    } else {
      displayText = `Placed on ${moment(orderDate).format('MMM D, YYYY')}`;
    }

    return <span>{displayText}</span>;
  };

  return (
    <div className="mx-auto">
      {isLoading ? (
        <div className="w-full flex items-center justify-center h-96">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="w-full flex justify-between items-end max-w-3xl mx-auto">
            <div className="flex flex-col border border-gray-200 rounded-md p-4">
              <p className="text-sm text-stone-800">Order: {order?._id}</p>
              <p className="text-xs text-stone-600">
                <ElapsedTimeOrDate orderDate={order?.placedOn} />
              </p>
            </div>

            <div className="flex">
              <div className="flex items-center gap-4">
                {order?.viewed && (
                  <Tooltip
                    style="light"
                    arrow={false}
                    content={
                      <p className="text-xs text-stone-800">
                        Customer opened the project link
                      </p>
                    }
                  >
                    <div className="flex items-center gap-1">
                      <Flink size={14} />
                      <p className="text-stone-600 text-xs">Opened</p>
                    </div>
                  </Tooltip>
                )}
                {order?.reviewed && (
                  <button
                    type="button"
                    onClick={() => setOpenRev(!openRev)}
                    className="flex items-center gap-1"
                  >
                    <MessageSquare size={14} />
                    <p className="text-stone-600 text-xs">
                      {openRev ? 'Hide review' : 'See review'}
                    </p>
                  </button>
                )}
              </div>
            </div>

            <Link
              to="/dashboard/orders"
              className="flex items-center text-xs text-stone-600"
            >
              Orders
              <ChevronRight size={16} />
            </Link>
          </div>

          <div className="flex flex-col max-w-3xl mx-auto border border-gray-200 rounded-md bg-white p-4 relative mt-2">
            <div className="flex items-start w-full gap-2">
              <div className="flex flex-col w-7/12 gap-2">
                <div className="flex flex-col">
                  <div className="border border-gray-200 rounded-md p-2 flex flex-col items-start gap-4">
                    <div className="flex flex-col w-full">
                      <div className="flex w-full items-center justify-between">
                        <div className="flex items-center gap-1">
                          <p className="text-stone-600 text-xs">Total:</p>
                          <p className="text-stone-800 text-sm font-bold">
                            $
                            {order?.total?.toLocaleString('en-US', {
                              minimumFractionDigits:
                                order?.total % 1 === 0 ? 0 : 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        </div>
                        <Badge color="success">Paid</Badge>
                      </div>
                    </div>

                    {order?.total > 0 ? (
                      <Timeline horizontal>
                        {order?.total >= 5 ? (
                          <Timeline.Item>
                            <Timeline.Point />

                            <Timeline.Content>
                              <Timeline.Time className="text-xs text-stone-600">
                                Fruntt
                              </Timeline.Time>
                              <Timeline.Title className="text-xs">
                                <Tooltip
                                  style="light"
                                  content={
                                    <p className="text-xs text-stone-800">
                                      Our platform fee
                                    </p>
                                  }
                                  className="w-32"
                                  arrow={false}
                                >
                                  -$1.00
                                </Tooltip>
                              </Timeline.Title>
                              <Timeline.Body></Timeline.Body>
                            </Timeline.Content>
                          </Timeline.Item>
                        ) : (
                          <Timeline.Item>
                            <Timeline.Point />
                            <Timeline.Content>
                              <Timeline.Time className="text-xs text-stone-600">
                                Fruntt
                              </Timeline.Time>
                              <Timeline.Title className="text-xs">
                                <Tooltip
                                  style="light"
                                  content={
                                    <p className="text-xs text-stone-800">
                                      Our platform fee
                                    </p>
                                  }
                                  className="w-32"
                                  arrow={false}
                                >
                                  -$0.25
                                </Tooltip>
                              </Timeline.Title>
                              <Timeline.Body></Timeline.Body>
                            </Timeline.Content>
                          </Timeline.Item>
                        )}
                        <Timeline.Item>
                          <Timeline.Point />
                          <Timeline.Content>
                            <Timeline.Time className="text-xs text-stone-600">
                              Stripe
                            </Timeline.Time>
                            <Timeline.Title className="text-xs">
                              <Tooltip
                                style="light"
                                content={
                                  <p className="text-xs text-stone-800">
                                    Stripe processing fee
                                  </p>
                                }
                                arrow={false}
                                className="w-44"
                              >
                                -${fullStripeFee}
                              </Tooltip>
                            </Timeline.Title>
                            <Timeline.Body></Timeline.Body>
                          </Timeline.Content>
                        </Timeline.Item>
                        {order?.tax > 0 ? (
                          <Timeline.Item>
                            <Timeline.Point />
                            <Timeline.Content>
                              <Timeline.Time className="text-xs text-stone-600">
                                {/* {order?.taxType === 'vat' ? 'VAT' : 'Tax'} */}
                                Tax/VAT
                              </Timeline.Time>
                              <Timeline.Title className="text-xs">
                                <Tooltip
                                  style="light"
                                  content={
                                    <p className="text-xs text-stone-800">
                                      Tax/VAT collected
                                    </p>
                                  }
                                  arrow={false}
                                  className="w-36"
                                >
                                  -${(order?.tax / 100).toFixed(2)}
                                </Tooltip>
                              </Timeline.Title>
                              <Timeline.Body></Timeline.Body>
                            </Timeline.Content>
                          </Timeline.Item>
                        ) : (
                          ''
                        )}
                        {order?.paymentOrigin === 'US' ? (
                          ''
                        ) : (
                          <Timeline.Item>
                            <Timeline.Point />
                            <Timeline.Content>
                              <Timeline.Time className="text-xs text-stone-600">
                                Intl
                              </Timeline.Time>
                              <Timeline.Title className="text-xs">
                                <Tooltip
                                  style="light"
                                  content={
                                    <p className="text-xs text-stone-800">
                                      International payment fee
                                    </p>
                                  }
                                  arrow={false}
                                  className="w-52"
                                >
                                  -${IntFee.toFixed(2)}
                                </Tooltip>
                              </Timeline.Title>
                              <Timeline.Body></Timeline.Body>
                            </Timeline.Content>
                          </Timeline.Item>
                        )}

                        <Timeline.Item>
                          <Timeline.Point />
                          <Timeline.Content>
                            <Timeline.Time className="text-xs text-stone-600">
                              You earn
                            </Timeline.Time>
                            <Timeline.Title className="text-xs">
                              <Tooltip
                                style="light"
                                content={
                                  <p className="text-xs text-stone-800">
                                    What you earn from the sale
                                  </p>
                                }
                                arrow={false}
                                className="w-52"
                              >
                                $
                                {order?.taxId ? (
                                  <>
                                    {order?.total >= 5
                                      ? (
                                          youMakeAfterFrunttHighFee - taxAmount
                                        ).toFixed(2)
                                      : (
                                          youMakeAfterFrunttLowFee - taxAmount
                                        ).toFixed(2)}
                                  </>
                                ) : (
                                  <>
                                    {order?.total >= 5
                                      ? youMakeAfterFrunttHighFee.toFixed(2)
                                      : youMakeAfterFrunttLowFee.toFixed(2)}
                                  </>
                                )}
                              </Tooltip>
                            </Timeline.Title>
                            <Timeline.Body></Timeline.Body>
                          </Timeline.Content>
                        </Timeline.Item>
                      </Timeline>
                    ) : (
                      <Timeline horizontal>
                        <Timeline.Item>
                          <Timeline.Point />
                          <Timeline.Content>
                            <Timeline.Time className="text-xs text-stone-600">
                              Fruntt
                            </Timeline.Time>
                            <Timeline.Title className="text-xs">
                              <Tooltip
                                style="light"
                                content={
                                  <p className="text-xs text-stone-800">
                                    Our fee
                                  </p>
                                }
                                className="w-24"
                                arrow={false}
                              >
                                -$0.00
                              </Tooltip>
                            </Timeline.Title>
                            <Timeline.Body></Timeline.Body>
                          </Timeline.Content>
                        </Timeline.Item>

                        <Timeline.Item>
                          <Timeline.Point />
                          <Timeline.Content>
                            <Timeline.Time className="text-xs text-stone-600">
                              Stripe
                            </Timeline.Time>
                            <Timeline.Title className="text-xs">
                              <Tooltip
                                style="light"
                                content={
                                  <p className="text-xs text-stone-800">
                                    Stripe processing fee
                                  </p>
                                }
                                className="w-44"
                                arrow={false}
                              >
                                -$0.00
                              </Tooltip>
                            </Timeline.Title>
                            <Timeline.Body></Timeline.Body>
                          </Timeline.Content>
                        </Timeline.Item>

                        {order?.country?.code === 'US' ? (
                          ''
                        ) : (
                          <Timeline.Item>
                            <Timeline.Point />
                            <Timeline.Content>
                              <Timeline.Time className="text-xs text-stone-600">
                                Intl
                              </Timeline.Time>
                              <Timeline.Title className="text-xs">
                                <Tooltip
                                  style="light"
                                  content={
                                    <p className="text-xs text-stone-800">
                                      International fee
                                    </p>
                                  }
                                  className="w-32"
                                  arrow={false}
                                >
                                  -$0.00
                                </Tooltip>
                              </Timeline.Title>
                              <Timeline.Body></Timeline.Body>
                            </Timeline.Content>
                          </Timeline.Item>
                        )}

                        <Timeline.Item>
                          <Timeline.Point />
                          <Timeline.Content>
                            <Timeline.Time className="text-xs text-stone-600">
                              You earn
                            </Timeline.Time>
                            <Timeline.Title className="text-xs">
                              <Tooltip
                                style="light"
                                content={
                                  <p className="text-xs text-stone-800">
                                    What you earn
                                  </p>
                                }
                                className="w-32"
                                arrow={false}
                              >
                                -$0.00
                              </Tooltip>
                            </Timeline.Title>
                            <Timeline.Body></Timeline.Body>
                          </Timeline.Content>
                        </Timeline.Item>
                      </Timeline>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col">
                    <p className="text-stone-600 text-xs">Purchased by:</p>
                    <p className="text-xs text-stone-800">{order?.name}</p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-stone-600 text-xs">Delivered to:</p>
                    <p className="text-xs text-stone-800">{order?.email}</p>
                  </div>

                  <div className="flex flex-col">
                    <p className="text-stone-600 text-xs">Located in:</p>

                    <div className="flex items-center">
                      <ReactCountryFlag
                        countryCode={order?.country?.code}
                        className="mr-1"
                      />
                      <p className="text-xs text-stone-800">
                        {order?.country?.name}
                      </p>
                    </div>
                  </div>
                  {order?.discount?.code ? (
                    <div className="flex flex-col">
                      <p className="text-stone-600 text-xs">
                        Discount code used:
                      </p>
                      <p className="text-xs text-stone-800">
                        {order?.discount?.code}
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="w-5/12 flex flex-col relative">
                <img
                  src={order?.item?.coverImages[0]?.url}
                  className="rounded-md w-full h-52 object-fill"
                />
                {order?.discount?.code ? (
                  <div className="p-2 pb-1 pt-1 bg-gray-50 border border-gray-200 rounded-md absolute top-0 right-0 mt-2 mr-2">
                    {order?.discount?.percent ? (
                      <p className="text-stone-800 text-xs">
                        Customer saved{' '}
                        <span className="font-bold">
                          {order?.discount?.amount}%
                        </span>
                      </p>
                    ) : (
                      <p className="text-stone-800 text-xs">
                        Customer saved{' '}
                        <span className="font-bold">
                          ${order?.discount?.amount}
                        </span>
                      </p>
                    )}
                  </div>
                ) : (
                  ''
                )}
                <p className="text-stone-600 text-xs mt-1">Item:</p>
                <p className="text-xs text-stone-800">{order?.item?.title}</p>
                {version ? (
                  <div className="flex flex-col mt-1">
                    <p className="text-stone-600 text-xs">Variation:</p>
                    <div className="p-2 rounded-md border border-gray-200 w-full flex flex-col">
                      <p className="text-xs text-stone-800">{version?.title}</p>
                      <p className="text-xs text-stone-600">
                        {version?.description}
                      </p>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          {openRev ? (
            <div className="flex flex-col gap-2 max-w-3xl mx-auto border border-gray-200 rounded-md mt-2 p-4 relative">
              <div className="absolute right-0 top-0 mr-2 mt-2">
                <button
                  type="button"
                  onClick={() => setOpenRev(!openRev)}
                  className="text-xs text-stone-800 flex items-center gap-1"
                >
                  Hide <ChevronUp size={14} />
                </button>
              </div>
              <Rating
                value={order?.review?.rating}
                precision={0.5}
                size="small"
                readOnly
              />
              {order?.review?.content ? (
                <textarea
                  disabled
                  className="w-full bg-gray-50 rounded-md p-2 text-xs resize-none h-18 border-none"
                  value={order?.review?.content}
                />
              ) : (
                <div className="w-full flex items-center justify-center rounded-md bg-gray-50 h-14">
                  <p className="text-stone-600 text-xs">No written review</p>
                </div>
              )}
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};

export default Desktop;
