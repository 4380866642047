import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import {
  Check,
  ChevronRight,
  Clipboard,
  Edit,
  ExternalLink,
  Layers,
  MoreVertical,
  Plus,
  Settings,
  Share,
  Trash,
} from 'react-feather';
import { Badge, Dropdown, Spinner } from 'flowbite-react';
import Modal from 'react-modal';
import { useDeleteProductMutation } from '../../../api/productsApiSlice';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../../redux/toastSlice';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');

const ProductsDesktop = ({ product, currentUser, refetch }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [deleteMod, setDeleteMod] = useState(false);
  const [shareMod, setShareMod] = useState(false);
  const [copied, setCopied] = useState(false);
  const [actionItem, setActionItem] = useState({});

  const [deleteProduct, result] = useDeleteProductMutation();

  //stuff for pagination
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;

  const endOffset = itemOffset + itemsPerPage;

  const currentItems = product.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(product.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % product.length;

    setItemOffset(newOffset);

    window.scroll(0, 0);
  };
  //end of pagination stuff

  const handleOpenShareModal = (prod) => {
    setActionItem(prod);
    setShareMod(!shareMod);
  };

  const handleCloseShareModal = () => {
    setActionItem({});
    setShareMod(!shareMod);
  };

  const handleOpenDelModal = (prod) => {
    setActionItem(prod);
    setDeleteMod(!deleteMod);
  };

  const handleCloseDelModal = () => {
    setActionItem({});
    setDeleteMod(!deleteMod);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(
        `https://fruntt.com/${actionItem?.pageName}/${actionItem?.url}`
      )
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
      })
      .catch((err) => {
        console.error('Failed to copy!', err);
      });
  };

  const handleDelete = async () => {
    const deleteItemReq = await deleteProduct(actionItem._id).unwrap();

    if (deleteItemReq === 'Item deleted') {
      refetch();
      setDeleteMod(false);
      setActionItem({});
      dispatch(showNotification('Item deleted'));
      navigate('/dashboard/items');
    } else {
      return;
    }
  };

  const noItem = currentUser?.setup ? (
    <div
      className="flex flex-col gap-2 border-gray-200 items-center justify-center rounded-md w-full border bg-white"
      style={{ height: '600px' }}
    >
      <Layers size={18} className="text-stone-800" />
      <p className="text-stone-800 text-sm">No items</p>
      <p className="text-stone-600 text-xs w-96 text-center">
        Create, save, and publish items here. Publishing an item for the first
        time requires approval from us.
      </p>

      <Link
        to="/dashboard/new/item"
        className="flex items-center justify-center bg-gray-200 text-xs text-stone-800 rounded-md p-1 pl-2 pr-2"
      >
        Create Item
      </Link>
    </div>
  ) : (
    <div
      className="flex flex-col gap-2 border-gray-200 items-center justify-center rounded-md w-full border bg-white"
      style={{ height: '600px' }}
    >
      <Layers size={18} className="text-stone-800" />
      <p className="text-stone-800 text-sm">No items</p>
      <p className="text-stone-600 text-xs w-72 text-center">
        You need to finish your account setup in settings before creating an
        item.
      </p>

      <Link
        to="/settings"
        className="flex items-center justify-center gap-1 bg-gray-200 text-xs text-stone-800 rounded-md p-1 pl-2 pr-2"
      >
        <Settings size={14} />
        <p className="text-xs">Settings</p>
      </Link>
    </div>
  );

  return product.length ? (
    <div className="w-full">
      <Modal
        isOpen={shareMod}
        onRequestClose={handleCloseShareModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="w-full flex flex-col gap-2">
          <div className="flex flex-col">
            <p className="text-stone-800 text-sm">Copy Item URL</p>
            <p className="text-stone-600 text-xs">
              Share this item URL with customers
            </p>
          </div>
          {copied && (
            <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
              <Check size={16} className="text-green-400" />
              <p className="text-stone-800 text-xs">
                Item URL copied to clipboard
              </p>
            </div>
          )}
          <div className="w-full flex items-center gap-1">
            <input
              type="text"
              className="border border-gray-200 w-72 bg-gray-50 rounded-md text-xs"
              value={`https://fruntt.com/${actionItem?.pageName}/${actionItem?.url}`}
              disabled
            />
            <button
              type="button"
              onClick={copyToClipboard}
              className="bg-gray-200 p-2 rounded-md"
            >
              <Clipboard size={14} className="text-stone-800" />
            </button>
          </div>
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
              onClick={handleCloseShareModal}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={deleteMod}
        onRequestClose={handleCloseDelModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="w-full flex flex-col gap-2">
          <p className="text-stone-800 text-sm">
            Are you sure you want to delete this item?
          </p>
          <div className="flex flex-col">
            <p className="text-xs text-stone-600">ID</p>
            <p className="text-xs text-stone-800">{actionItem?._id}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-xs text-stone-600">Title</p>
            <p className="text-xs text-stone-800">{actionItem?.title}</p>
          </div>
          <div className="flex items-center gap-2">
            {result?.isLoading ? (
              <Spinner />
            ) : (
              <>
                <button
                  type="button"
                  className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                  onClick={handleCloseDelModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleDelete}
                  className="bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                >
                  Delete
                </button>
              </>
            )}
          </div>
        </div>
      </Modal>
      <div className="w-full flex justify-between items-end">
        <div className="flex flex-col justify-center bg-white rounded-md border border-gray-200 p-2">
          <p className="text-sm text-stone-800">Items</p>
          <p className="text-xs text-stone-600">
            View all draft, in review, and published items
          </p>
        </div>

        <div className="flex items-center">
          <p className="text-stone-600 text-xs">
            {product.length == 1
              ? `${product.length} item`
              : `${product.length} items`}
          </p>
          <Link
            className="rounded-md p-1 flex items-center justify-center ml-2 font-medium bg-gray-200"
            to="/dashboard/new/item"
          >
            <Plus size={14} />
          </Link>
        </div>
      </div>

      <div
        className="flex flex-col"
        // style={{ height: '500px' }}
      >
        {currentItems.map((prod, index) => (
          <div
            className="border border-gray-200 rounded-md bg-white relative flex mt-2"
            key={index}
          >
            <div className="w-2/12 h-full p-2">
              <img
                src={
                  prod?.coverImages.length
                    ? prod?.coverImages[0]?.url
                    : prod?.coverImage?.url
                }
                className="rounded-md w-full h-20 object-cover"
              />
            </div>

            <div className="w-10/12 flex flex-col items-start gap-2 p-2">
              <p className="text-stone-800 text-sm">
                {prod?.title} - {}
                {prod?.free
                  ? 'FREE'
                  : prod?.payChoice
                  ? `${prod?.currency?.symbol}${prod?.price?.toLocaleString(
                      'en-US',
                      {
                        minimumFractionDigits: prod?.price % 1 === 0 ? 0 : 2,
                        maximumFractionDigits: 2,
                      }
                    )}+`
                  : `${prod?.currency?.symbol}${prod?.price?.toLocaleString(
                      'en-US',
                      {
                        minimumFractionDigits: prod?.price % 1 === 0 ? 0 : 2,
                        maximumFractionDigits: 2,
                      }
                    )}`}
              </p>

              {prod?.published && (
                <Link
                  to={`/${prod?.pageName}/${prod?.url}`}
                  className="text-xs text-stone-600 flex gap-1"
                  target="_blank"
                >
                  {`fruntt.com/${prod?.pageName}/${prod?.url}`}{' '}
                  <ExternalLink size={14} />
                </Link>
              )}

              <div className="w-full flex justify-start">
                {prod?.inReview ? (
                  <Badge color="warning" size="xs">
                    In Review
                  </Badge>
                ) : (
                  <>
                    {prod?.published ? (
                      <Badge color="success" size="xs">
                        Published
                      </Badge>
                    ) : (
                      <Badge color="info" size="xs">
                        Draft
                      </Badge>
                    )}
                  </>
                )}
              </div>

              <div className="flex gap-2 items-center absolute bottom-0 right-0 mt-1 mr-1 p-2">
                <Link
                  to={`/dashboard/item/reviews/${prod?._id}`}
                  className="text-xs text-stone-600"
                >
                  {prod.numberOfReviews == 1
                    ? `${prod.numberOfReviews} review`
                    : `${prod.numberOfReviews} reviews`}{' '}
                </Link>
                {prod?.published && (
                  <p className="text-xs text-stone-600">
                    {prod.numberOfSales == 1
                      ? `${prod.numberOfSales} sale`
                      : `${prod.numberOfSales} sales`}{' '}
                  </p>
                )}
              </div>
            </div>

            {/* <Link to={`/dashboard/item/edit/${prod?._id}`}>
              <button className="absolute text-stone-800 rounded-md right-0 top-0 mt-2 mr-2">
                <Edit size={14} />
              </button>
            </Link> */}

            <Dropdown
              label=""
              dismissOnClick={true}
              renderTrigger={() => (
                <MoreVertical
                  size={16}
                  className="mt-2 mr-2 hover:cursor-pointer"
                />
              )}
              className="absolute right-0 top-0 w-32"
            >
              <Dropdown.Item as="div">
                <Link
                  to={`/dashboard/item/edit/${prod?._id}`}
                  className="text-xs text-stone-800 flex items-center justify-between w-full"
                >
                  View & Edit <Edit size={14} />
                </Link>
              </Dropdown.Item>

              {prod?.published && (
                <Dropdown.Item as="div">
                  <button
                    type="button"
                    onClick={() => handleOpenShareModal(prod)}
                    className="text-stone-800 flex items-center justify-between w-full text-xs"
                  >
                    Share <Share size={14} />
                  </button>
                </Dropdown.Item>
              )}

              <Dropdown.Item as="div">
                <button
                  type="button"
                  onClick={() => handleOpenDelModal(prod)}
                  className="text-stone-800 flex items-center justify-between w-full text-xs"
                >
                  Delete <Trash size={14} />
                </button>
              </Dropdown.Item>
            </Dropdown>
          </div>
        ))}
      </div>
      {product.length > 5 ? (
        <div className="w-full flex justify-end mx-auto mt-2">
          <div className="">
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              marginPagesDisplayed={0}
              pageRangeDisplayed={0}
              pageCount={pageCount}
              previousLabel="Prev"
              renderOnZeroPageCount={null}
              className="flex items-center"
              activeLinkClassName="activePage"
              pageLinkClassName="notActivePage"
              breakLinkClassName="breakLink"
              disabledClassName="disabled"
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  ) : (
    <div className="h-">{noItem}</div>
  );
};

export default ProductsDesktop;
