import { Spinner } from 'flowbite-react';
import React from 'react';
import { Check, ChevronLeft } from 'react-feather';
import Select from 'react-select';

const MobileNoPayForm = ({
  handleConfirmOrder,
  setEmail,
  email,
  setName,
  handleCountry,
  options,
  placingOrder,
  item,
  versionId,
  varTitle,
  total,
  handleResetCheckout,
  disApplied,
  discount,
}) => {
  const itemPrice = versionId
    ? item?.versions?.find((version) => version._id === versionId)?.price // Find the matching version price
    : item?.price;

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="w-full">
        <p className="text-stone-600 text-xs mb-1">Buying</p>
        <div className="w-full border border-gray-200 rounded-md p-2 flex flex-col gap-1 items-start">
          <p className="text-stone-800 text-sm font-bold">{item?.title}</p>
          <p className="text-stone-600 text-xs">{item?.description}</p>
          {versionId ? (
            <div className="w-full flex flex-col">
              <p className="text-stone-800 text-xs">Variation</p>
              <div className="flex items-center w-full justify-between p-2 rounded-md border border-gray-200">
                <div className="flex flex-col items-start">
                  <p className="text-xs text-stone-800">{varTitle}</p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="flex flex-col gap-2 w-full mt-1">
            {discount?.code ? (
              <div className="flex flex-col gap-2 w-full bg-gray-50 p-2 rounded-md">
                <div className="flex items-center justify-between w-full">
                  <p className="text-xs text-stone-800">Item:</p>
                  <p className="text-xs text-stone-800">
                    {item?.currency?.symbol}
                    {itemPrice?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>

                <div className="flex items-center justify-between w-full">
                  <p className="text-xs text-stone-800">Discount:</p>
                  <p className="text-xs text-stone-800">
                    -$
                    {(discount.percent
                      ? (itemPrice * discount.amount) / 100 // Calculate percentage discount
                      : discount.amount
                    ) // Use fixed discount amount
                      .toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </p>
                </div>

                <div className="flex items-center justify-between w-full">
                  <p className="text-xs text-stone-800 font-bold">Total:</p>
                  <p className="text-xs text-stone-800">
                    {item?.currency?.symbol}
                    {total?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between w-full bg-gray-50 p-2 rounded-md">
                <p className="text-xs text-stone-800 font-bold">Total:</p>
                <p className="text-xs text-stone-800">
                  {item?.currency?.symbol}
                  {total?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
            )}
          </div>
          {disApplied ? (
            <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2 mt-1">
              <Check size={16} className="text-green-400" />
              <p className="text-stone-800 text-xs">Discount applied</p>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <form className="flex flex-col w-full" onSubmit={handleConfirmOrder}>
        <p className="text-stone-600 text-xs">Customer</p>
        <input
          type="email"
          placeholder="Email"
          className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent hover:bg-gray-200 focus:bg-gray-200 0 w-full outline outline-0 rounded-md p-2 mt-1"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <div className="flex items-center mt-2">
          <input
            type="text"
            placeholder="Name"
            className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent hover:bg-gray-200 focus:bg-gray-200 0 w-3/6 outline outline-0 rounded-md p-2 mr-2"
            onChange={(e) => setName(e.target.value)}
          />
          <div className="w-3/6">
            <Select
              options={options}
              onChange={handleCountry}
              className="text-sm"
              placeholder="Country"
              menuPortalTarget={document.body}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: 'rgb(229 231 235)',
                  backgroundColor: 'rgb(249 250 251)',
                  borderWidth: 1,
                  '&:hover': {
                    backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  '&:focus': {
                    backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  fontSize: '12px',
                  borderRadius: '.375rem',
                  boxShadow: 'none',
                  zIndex: 99999,
                  position: 'relative',
                  height: 35,
                  minHeight: 35,
                }),
                indicatorsContainer: (provided) => ({
                  ...provided,
                  height: 35,
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 999999,
                  fontSize: '12px',
                }),
              }}
            />
          </div>
        </div>
        <div className="w-full flex items-center mt-2">
          <button
            type="button"
            disabled={placingOrder}
            onClick={handleResetCheckout}
            className="w-3/12 h-10 text-xs text-stone-800 bg-gray-200 rounded-md mr-2 flex items-center justify-center"
          >
            <ChevronLeft size={16} />
          </button>
          <button
            disabled={placingOrder}
            type="submit"
            className="w-9/12 h-10 bg-gray-200 text-stone-800 text-xs rounded-md"
          >
            {placingOrder ? <Spinner /> : 'Get Now'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default MobileNoPayForm;
