import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { Spinner } from 'flowbite-react';

const Products = ({ products, gettingProducts, gotProducts }) => {
  //stuff for pagination//
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 1;

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = products.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(products.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products.length;

    setItemOffset(newOffset);
  };
  //pagination stuff ends here//

  let content;

  if (gettingProducts) {
    content = (
      <div className="w-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  } else if (gotProducts) {
    content = isMobile ? (
      <div className="flex flex-col gap-2 items-center">
        <p className="text-stone-600 text-sm">Recommended Items</p>

        <div className="w-full flex flex-col gap-4">
          {products.map((product, index) => (
            <Link to={`/${product?.pageName}/${product?.url}`} key={index}>
              <div className="border border-gray-200 rounded-md bg-white relative flex flex-col gap-2 hover:bg-gray-50 p-2">
                <div className="w-full relative">
                  <img
                    src={product?.coverImage?.url}
                    className="rounded-md w-full h-32 object-cover"
                  />
                  <div className="absolute top-0 right-0 bg-gray-100/70 mt-1 mr-1 rounded-md p-1 pl-2 pr-2">
                    <p className="text-xs">
                      {product?.free
                        ? 'FREE'
                        : product?.payChoice
                        ? `$${product?.price?.toLocaleString('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}+`
                        : `$${product?.price?.toLocaleString('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}`}
                    </p>
                  </div>
                </div>

                <div className="w-full flex flex-col h-16 gap-2 items-start">
                  <p className="text-stone-800 text-sm">{product?.title}</p>
                  <p className="text-xs text-stone-600">
                    {product?.description}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    ) : (
      <div className="max-w-7xl flex flex-col gap-2 items-center">
        <p className="text-stone-600 text-sm">Recommended Items</p>

        <div className="w-full grid grid-cols-3 gap-4">
          {products.map((product, index) => (
            <Link key={index} to={`/${product?.pageName}/${product?.url}`}>
              <div className="border border-gray-200 rounded-md bg-white relative flex flex-col gap-2 hover:bg-gray-50 p-2">
                <div className="w-full h-full relative">
                  <img
                    src={product?.coverImage?.url}
                    className="rounded-md w-full h-44 object-cover"
                  />
                  <div className="absolute top-0 right-0 bg-gray-100/70 mt-1 mr-1 rounded-md p-1 pl-2 pr-2">
                    <p className="text-xs text-stone-800 font-bold">
                      {product?.free
                        ? 'FREE'
                        : product?.payChoice
                        ? `$${product?.price?.toLocaleString('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}+`
                        : `$${product?.price?.toLocaleString('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}`}
                    </p>
                  </div>
                </div>

                <div className="w-full flex flex-col gap-2 items-start h-16">
                  <p className="text-stone-800 text-sm font-bold">
                    {product?.title}
                  </p>
                  <p className="text-xs text-stone-600">
                    {product?.description}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }

  return content;
};

export default Products;
