import { PaymentElement } from '@stripe/react-stripe-js';
import React, { useEffect, useRef } from 'react';

const PayForm = ({ elements, stripe }) => {
  const paymentElementRef = useRef(null);

  useEffect(() => {
    if (!stripe || !elements) {
      return; // Ensure that stripe and elements are initialized
    }

    // Create a payment element instance
    const paymentElement = elements.create('payment', {
      fields: {
        billingDetails: {
          address: {
            country: 'never',
            postalCode: 'never',
          },
        },
      },
    });

    // Mount the payment element
    paymentElement.mount(paymentElementRef.current);

    // Cleanup function to unmount the element when the component unmounts
    return () => {
      paymentElement.unmount();
    };
  }, [stripe, elements]);

  return (
    <div className="w-full">
      {/* <PaymentElement /> */}
      <div id="payment-element" ref={paymentElementRef} />
    </div>
  );
};

export default PayForm;
