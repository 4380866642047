import React from 'react';
import NoPayForm from './NoPayForm';
import CheckoutForm from './CheckoutForm';
import Cookies from 'js-cookie';

//stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { isMobile } from 'react-device-detect';

const Checkout = ({
  orderId,
  item,
  total,
  setReadyForPayment,
  handleResetCheckout,
  refetch,
  setError,
  error,
  versionId,
  disCode,
  setTaxDis,
  setPaymentForm,
  varTitle,
  handleApplyDiscount,
  setDisCode,
  disApplied,
  disErr,
  applyingDis,
  discount,
}) => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const stripeLoader = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const stripeOptions = {
    // clientSecret: order?.clientId,
    mode: 'payment',
    amount: Math.round(total * 100),
    currency: 'usd',
    appearance: {
      labels: 'floating',
      theme: 'stripe',
      variables: {
        colorPrimary: '#fff',
        colorBackground: '#ffffff',
        colorText: '#000',
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        fontSizeBase: isMobile ? '12px' : '12px',
        borderRadius: '6px',
        spacingUnit: '1px',
        gridColumnSpacing: '8px',
        gridRowSpacing: '8px',
      },
      rules: {
        '.Input': {
          borderColor: 'rgb(229 231 235)',
          backgroundColor: 'rgb(249 250 251)',
          boxShadow: 'none',
        },
        '.Input:focus': {
          borderColor: 'rgb(229 231 235)',
          backgroundColor: 'rgb(229 231 235)',
          outline: 0,
          boxShadow: 'none',
        },
        '.Input:hover': {
          borderColor: 'rgb(229 231 235)',
          backgroundColor: 'rgb(229 231 235)',
          outline: 0,
          boxShadow: 'none',
        },
        '.Label': {
          color: '#757575',
        },
      },
    },
  };
  return (
    <div>
      <div>
        {total === 0 ? (
          <NoPayForm
            orderId={orderId}
            total={total}
            item={item}
            setReadyForPayment={setReadyForPayment}
            handleResetCheckout={handleResetCheckout}
            error={error}
            setError={setError}
            versionId={versionId}
            varTitle={varTitle}
            user={currentUser}
            disCode={disCode}
            disApplied={disApplied}
            discount={discount}
          />
        ) : (
          <Elements stripe={stripeLoader} options={stripeOptions}>
            <CheckoutForm
              orderId={orderId}
              total={total}
              item={item}
              refetch={refetch}
              handleResetCheckout={handleResetCheckout}
              error={error}
              setError={setError}
              versionId={versionId}
              user={currentUser}
              disCode={disCode}
              setTaxDis={setTaxDis}
              varTitle={varTitle}
              handleApplyDiscount={handleApplyDiscount}
              setDisCode={setDisCode}
              disApplied={disApplied}
              disErr={disErr}
              applyingDis={applyingDis}
              discount={discount}
            />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default Checkout;
