import React from 'react';
import PayForm from './PayForm';
import Select from 'react-select';
import { availCountries } from '../../utils/availCountries';
import { Check, ChevronLeft, X } from 'react-feather';
import { Spinner } from 'flowbite-react';

const MobileCheckoutForm = ({
  paymentReady,
  email,
  setEmail,
  setName,
  handleCountry,
  setZip,
  elements,
  stripe,
  placingOrder,
  handleResetCheckout,
  isLoading,
  paying,
  handleConfirmOrder,
  updatingOrder,
  handleUpdateOrder,
  item,
  versionId,
  varTitle,
  total,
  tax,
  taxType,
  disApplied,
  disCode,
  disErr,
  applyingDis,
  handleApplyDiscount,
  setDisCode,
  discount,
}) => {
  const itemPrice = versionId
    ? item?.versions?.find((version) => version._id === versionId)?.price // Find the matching version price
    : item?.price;

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="w-full">
        <p className="text-stone-600 text-xs mb-1">Buying</p>
        <div className="w-full border border-gray-200 rounded-md p-2 flex flex-col gap-1 items-start">
          <p className="text-stone-800 text-sm font-bold">{item?.title}</p>
          <p className="text-stone-600 text-xs">{item?.description}</p>
          {versionId ? (
            <div className="w-full flex flex-col">
              <p className="text-stone-800 text-xs">Variation</p>
              <div className="flex items-center w-full justify-between p-2 rounded-md border border-gray-200">
                <div className="flex flex-col items-start">
                  <p className="text-xs text-stone-800">{varTitle}</p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="flex flex-col gap-2 w-full mt-1 p-2 bg-gray-50 rounded-md">
            {paymentReady ? (
              <>
                <div className="flex items-center justify-between w-full">
                  <p className="text-xs text-stone-800">Item:</p>
                  <p className="text-xs text-stone-800">
                    {item?.currency?.symbol}
                    {itemPrice?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>

                {discount?.code ? (
                  <div className="flex items-center justify-between w-full">
                    <p className="text-xs text-stone-800">Discount:</p>
                    <p className="text-xs text-stone-800">
                      -$
                      {(discount.percent
                        ? (itemPrice * discount.amount) / 100 // Calculate percentage discount
                        : discount.amount
                      ) // Use fixed discount amount
                        .toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </p>
                  </div>
                ) : (
                  ''
                )}

                <div className="flex items-center justify-between w-full">
                  <p className="text-xs text-stone-800">
                    {/* {taxType === 'vat' ? 'VAT:' : 'Tax:'} */}
                    Tax/VAT
                  </p>
                  <p className="text-xs text-stone-800">
                    $
                    {tax?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
              </>
            ) : (
              ''
            )}

            <div className="flex items-center justify-between w-full">
              <p className="text-xs text-stone-800 font-bold">Total:</p>
              <p className="text-xs text-stone-800">
                {item?.currency?.symbol}
                {(total + tax)?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
          </div>
        </div>
        {item?.discounts && !paymentReady && (
          <div className="flex flex-col gap-2 w-full mt-2">
            {disErr && (
              <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                <X size={16} className="text-red-500" />
                <p className="text-stone-800 text-xs">{disErr}</p>
              </div>
            )}
            {disApplied ? (
              <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                <Check size={16} className="text-green-400" />
                <p className="text-stone-800 text-xs">Discount applied</p>
              </div>
            ) : (
              <div className="flex items-center gap-1">
                <input
                  type="text"
                  className={`border border-gray-200 hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-md p-2 bg-gray-50 flex-1 focus:bg-gray-200 focus:border-gray-200`}
                  placeholder="Enter code"
                  onChange={(e) => setDisCode(e.target.value)}
                />
                <button
                  type="button"
                  onClick={handleApplyDiscount}
                  className="p-2 bg-gray-200 text-stone-800 border border-gray-200 rounded-md text-xs"
                  disabled={applyingDis || disApplied || !disCode}
                >
                  Apply Discount
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <form className="flex flex-col w-full">
        {paymentReady ? (
          ''
        ) : (
          <>
            <p className="text-stone-600 text-xs">Customer</p>
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-col gap-2 mt-1">
                <input
                  type="email"
                  placeholder="Email"
                  className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent hover:bg-gray-200 focus:bg-gray-200 0 w-full outline outline-0 rounded-md p-2"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <input
                  type="text"
                  placeholder="Name"
                  className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent hover:bg-gray-200 focus:bg-gray-200 0 w-full outline outline-0 rounded-md p-2"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="flex items-center gap-2">
                <Select
                  options={availCountries}
                  onChange={handleCountry}
                  className="w-full"
                  menuPortalTarget={document.body}
                  placeholder="Country"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: 'rgb(229 231 235)',
                      backgroundColor: 'rgb(249 250 251)',
                      borderWidth: 1,
                      '&:hover': {
                        backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                      },
                      '&:focus': {
                        backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                      },
                      borderRadius: '.375rem',
                      boxShadow: 'none',
                      zIndex: 0,
                      fontSize: '12px',
                      minHeight: 35,
                      height: 35,
                    }),
                    indicatorsContainer: (provided) => ({
                      ...provided,
                      height: 35,
                    }),
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      fontSize: '12px',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? 'rgb(229 231 235)'
                        : state.isFocused
                        ? 'rgb(249 250 251)'
                        : '',
                      color: 'black',
                    }),
                  }}
                />

                <input
                  type="text"
                  placeholder="Postal Code"
                  className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent hover:bg-gray-200 focus:bg-gray-200 0 w-full outline outline-0 rounded-md p-2"
                  onChange={(e) => setZip(e.target.value)}
                />
              </div>
            </div>
          </>
        )}
        {paymentReady ? (
          <>
            <p className="text-stone-600 text-xs mb-1 mt-2">Payment</p>
            {/* <PaymentElement id="payment-element" /> */}
            <PayForm elements={elements} stripe={stripe} />
          </>
        ) : (
          ''
        )}

        {paymentReady ? (
          <div className="w-full flex items-center mt-2">
            <button
              type="button"
              disabled={placingOrder}
              onClick={handleResetCheckout}
              className="w-3/12 h-10 text-stone-800 bg-gray-200 rounded-md mr-2 flex items-center justify-center"
            >
              <ChevronLeft size={16} />
            </button>
            <button
              disabled={placingOrder || isLoading || paying}
              type="button"
              onClick={handleConfirmOrder}
              className="w-9/12 h-10 bg-gray-200 text-stone-800 text-xs rounded-md"
            >
              {placingOrder ? <Spinner /> : 'Buy Now'}
            </button>
          </div>
        ) : (
          <div className="w-full flex items-center mt-2">
            <button
              type="button"
              disabled={updatingOrder}
              onClick={handleResetCheckout}
              className="w-3/12 h-10 text-stone-800 bg-gray-200 rounded-md mr-2 flex items-center justify-center"
            >
              <ChevronLeft size={16} />
            </button>
            <button
              disabled={updatingOrder || isLoading || paying}
              type="button"
              onClick={handleUpdateOrder}
              className="w-9/12 h-10 bg-gray-200 text-stone-800 text-xs rounded-md"
            >
              {updatingOrder ? <Spinner /> : 'Payment'}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default MobileCheckoutForm;
