import React, { useEffect, useMemo, useState } from 'react';
import { useUpdateOrderMutation } from '../../api/ordersApiSlice';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Alert, Spinner } from 'flowbite-react';
import { AlertCircle, Check, ChevronLeft, X } from 'react-feather';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';
import MobileNoPayForm from './MobileNoPayForm';

const NoPayForm = ({
  orderId,
  error,
  setError,
  handleResetCheckout,
  total,
  item,
  versionId,
  varTitle,
  user,
  disCode,
  disApplied,
  discount,
}) => {
  const [email, setEmail] = useState(user?.email || '');
  const [name, setName] = useState('');
  const [country, setCountry] = useState({});
  const [placingOrder, setPlacingOrder] = useState(false);

  const [updateOrder, result] = useUpdateOrderMutation();
  const navigate = useNavigate();

  //for country select
  const options = useMemo(() => countryList().getData(), []);

  const handleCountry = (value) => {
    setCountry(value);
  };

  const itemPrice = versionId
    ? item?.versions?.find((version) => version._id === versionId)?.price // Find the matching version price
    : item?.price;

  const handleConfirmOrder = async (e) => {
    e.preventDefault();

    //make sure all fields are filled out
    if (!name.trim() || !Object.keys(country).length || !email.trim()) {
      setError('Enter all customer details');
      return;
    }

    try {
      setPlacingOrder(true);

      const updatedOrderReq = await updateOrder({
        orderId: orderId,
        email: email.toLowerCase(),
        name: name,
        country: country,
        total: total,
        item: item,
        versionId: versionId,
        user: user ? user?._id : '',
        disCode: disCode,
      }).unwrap();

      //update the order on the server
      if (updatedOrderReq.msg === 'Order updated') {
        setPlacingOrder(false);
        Cookies.remove('orderId');
        navigate(
          `/order/${orderId}?oat=${updatedOrderReq?.order?.accessToken}`
        );
      } else {
        setPlacingOrder(false);
        setError('There was an error');
        return;
      }
    } catch (err) {
      setPlacingOrder(false);
      setError('There was an error');
      console.log(err);
      return;
    }
  };

  useEffect(() => {
    setError('');
  }, [email, name, country]);

  return isMobile ? (
    <MobileNoPayForm
      handleConfirmOrder={handleConfirmOrder}
      setEmail={setEmail}
      email={email}
      setName={setName}
      handleCountry={handleCountry}
      options={options}
      placingOrder={placingOrder}
      item={item}
      versionId={versionId}
      varTitle={varTitle}
      total={total}
      handleResetCheckout={handleResetCheckout}
      disApplied={disApplied}
      discount={discount}
    />
  ) : (
    <div className="w-full flex items-start gap-4 p-4">
      <form className="flex flex-col w-7/12" onSubmit={handleConfirmOrder}>
        <p className="text-stone-600 text-xs">Customer</p>
        <input
          type="email"
          placeholder="Email"
          className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent hover:bg-gray-200 focus:bg-gray-200 0 w-full outline outline-0 rounded-md p-2 mt-1"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <div className="flex items-center mt-2">
          <input
            type="text"
            placeholder="Name"
            className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent hover:bg-gray-200 focus:bg-gray-200 0 w-3/6 outline outline-0 rounded-md p-2 mr-2"
            onChange={(e) => setName(e.target.value)}
          />
          <div className="w-3/6">
            <Select
              options={options}
              onChange={handleCountry}
              className="text-sm"
              placeholder="Country"
              menuPortalTarget={document.body}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: 'rgb(229 231 235)',
                  backgroundColor: 'rgb(249 250 251)',
                  borderWidth: 1,
                  '&:hover': {
                    backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  '&:focus': {
                    backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  fontSize: '12px',
                  borderRadius: '.375rem',
                  boxShadow: 'none',
                  zIndex: 99999,
                  position: 'relative',
                  height: 35,
                  minHeight: 35,
                }),
                indicatorsContainer: (provided) => ({
                  ...provided,
                  height: 35,
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 999999,
                  fontSize: '12px',
                }),
              }}
            />
          </div>
        </div>
        <div className="w-full flex items-center mt-2">
          <button
            type="button"
            disabled={placingOrder}
            onClick={handleResetCheckout}
            className="w-3/12 h-10 text-xs text-stone-800 bg-gray-200 rounded-md mr-2 flex items-center justify-center"
          >
            <ChevronLeft size={16} />
          </button>
          <button
            disabled={placingOrder}
            type="submit"
            className="w-9/12 h-10 bg-gray-200 text-stone-800 text-xs rounded-md"
          >
            {placingOrder ? <Spinner /> : 'Get Now'}
          </button>
        </div>
      </form>
      <div className="w-5/12">
        <p className="text-stone-600 text-xs mb-1">Buying</p>
        <div className="w-full border border-gray-200 rounded-md p-2 flex flex-col gap-1 items-start">
          <p className="text-stone-800 text-sm font-bold">{item?.title}</p>
          <p className="text-stone-600 text-xs">{item?.description}</p>
          {versionId ? (
            <div className="w-full flex flex-col">
              <p className="text-stone-800 text-xs">Variation</p>
              <div className="flex items-center w-full justify-between p-2 rounded-md border border-gray-200">
                <div className="flex flex-col items-start">
                  <p className="text-xs text-stone-800">{varTitle}</p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="flex flex-col gap-2 w-full mt-1">
            {discount?.code ? (
              <div className="flex flex-col gap-2 w-full bg-gray-50 p-2 rounded-md">
                <div className="flex items-center justify-between w-full">
                  <p className="text-xs text-stone-800">Item:</p>
                  <p className="text-xs text-stone-800">
                    {item?.currency?.symbol}
                    {itemPrice?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>

                <div className="flex items-center justify-between w-full">
                  <p className="text-xs text-stone-800">Discount:</p>
                  <p className="text-xs text-stone-800">
                    -$
                    {(discount.percent
                      ? (itemPrice * discount.amount) / 100 // Calculate percentage discount
                      : discount.amount
                    ) // Use fixed discount amount
                      .toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </p>
                </div>

                <div className="flex items-center justify-between w-full">
                  <p className="text-xs text-stone-800 font-bold">Total:</p>
                  <p className="text-xs text-stone-800">
                    {item?.currency?.symbol}
                    {total?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between w-full bg-gray-50 rounded-md p-2">
                <p className="text-xs text-stone-800 font-bold">Total:</p>
                <p className="text-xs text-stone-800">
                  {item?.currency?.symbol}
                  {total?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
            )}
          </div>
        </div>
        {disApplied ? (
          <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2 mt-1">
            <Check size={16} className="text-green-400" />
            <p className="text-stone-800 text-xs">Discount applied</p>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default NoPayForm;
