import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import Cookies from 'js-cookie';

//mui
import Rating from '@mui/material/Rating';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import { useMarkAsViewedMutation } from '../../../api/ordersApiSlice';
import { Badge, Spinner } from 'flowbite-react';
import {
  ChevronDown,
  ChevronRight,
  ChevronUp,
  ExternalLink,
  Map,
  MessageSquare,
  X,
} from 'react-feather';
import moment from 'moment';
import { calcItemDiscount } from '../../../utils/calcItemDiscount';

const DesktopDownload = ({
  orderAndStore,
  setReview,
  setRating,
  handleSubmitReview,
  open,
  setOpen,
  isSubmitting,
}) => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const [close, setClose] = useState(false);
  const [openMore, setOpenMore] = useState(false);

  const [markAsViewed, result] = useMarkAsViewedMutation();
  const versionId = orderAndStore?.order?.versionId;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const markViewed = () => {
    const markViewed = async () => {
      try {
        const markAsViewedReq = await markAsViewed({
          orderId: orderAndStore?.order?._id,
        }).unwrap();
      } catch (err) {
        return;
      }
    };

    if (!orderAndStore?.order?.viewed) {
      markViewed();
    }
  };

  const version = orderAndStore?.order?.item?.versions?.find(
    (version) => version?._id === versionId
  );

  //to see if product info is empty or not
  var regex = /(<([^>]+)>)/gi;
  const hasInfo = !!orderAndStore?.order?.item?.info.replace(regex, '').length;

  const itemPrice = versionId
    ? version?.price
    : orderAndStore?.order?.item?.price;
  const discount = orderAndStore?.order?.discount;
  let discountAmount = 0; // Default discount amount

  // Determine the discount amount based on the discount type
  if (discount) {
    if (discount.percent) {
      // Calculate discount as a percentage of the item price
      discountAmount = (itemPrice * discount.amount) / 100;
    } else {
      // Use the fixed discount amount
      discountAmount = discount.amount;
    }
  }
  const orderTax = orderAndStore?.order?.tax / 100;

  return (
    <div className="mx-auto">
      <div className="max-w-2xl h-full mx-auto flex justify-between items-center border border-gray-200 rounded-md mt-8 p-2">
        {/* logo section */}
        <div>
          <Link to="/" className="h-full flex gap-1">
            <Map size={18} />
            <p
              className="font-black text-stone-800 text-md"
              style={{ fontFamily: 'Inter, sans-serif' }}
            >
              Fruntt
            </p>
          </Link>
        </div>

        <button
          type="button"
          onClick={() => setOpen(!open)}
          className="bg-gray-200 text-xs rounded-md text-stone-800 p-1 pl-2 pr-2"
        >
          {open ? <X size={16} /> : <MessageSquare size={16} />}
        </button>

        {currentUser ? (
          <Link to="/dashboard/library" className="h-full flex items-center">
            <p className="text-stone-800 text-xs">Library</p>
            <ChevronRight size={14} />
          </Link>
        ) : (
          <div className="flex gap-2 items-center">
            <Link
              to="/signup"
              className="flex text-xs p-1 pl-2 pr-2 items-center justify-center hover:bg-gray-200 text-stone-800 rounded-md"
            >
              <p className="font-medium">Signup</p>
            </Link>
            <Link
              to="/login"
              className="flex text-xs items-center justify-center bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2"
            >
              <p className="font-medium">Login</p>
            </Link>
          </div>
        )}
      </div>
      {close ? (
        ''
      ) : (
        <div className="max-w-2xl h-full mx-auto flex gap-2 items-center border border-gray-200 rounded-md p-2 mt-2">
          <X
            size={16}
            onClick={() => setClose(true)}
            className="hover:cursor-pointer"
          />
          <p className="text-xs text-stone-600">
            Success! The order was sent to{' '}
            <span className="font-bold">{orderAndStore?.order?.email}</span>
          </p>
        </div>
      )}
      {open ? (
        <>
          {isSubmitting ? (
            <div className="max-w-2xl mx-auto border border-gray-200 rounded-md flex items-center justify-center p-6 mt-2">
              <Spinner />
            </div>
          ) : (
            <div className="max-w-2xl mx-auto border border-gray-200 w-full rounded-md p-2 mt-2">
              {orderAndStore?.order?.reviewed ? (
                <div className="flex flex-col gap-2 w-full">
                  <Rating
                    value={orderAndStore?.order?.review?.rating}
                    precision={0.5}
                    size="small"
                    readOnly
                  />

                  {orderAndStore?.order?.review?.content ? (
                    <textarea
                      disabled
                      className="border border-gray-200 w-full bg-gray-50 focus:border-gray-200 rounded-md p-2 text-xs resize-none h-18"
                      value={orderAndStore?.order?.review?.content}
                    />
                  ) : (
                    <div className="w-full flex items-center justify-center rounded-md bg-gray-50 h-14">
                      <p className="text-stone-600 text-xs">
                        No written review
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  {open ? (
                    <div className="w-full">
                      <form
                        onSubmit={handleSubmitReview}
                        className="w-full flex flex-col items-start gap-2"
                      >
                        <Rating
                          onChange={(e) => setRating(e.target.value)}
                          precision={0.5}
                          size="small"
                        />
                        <textarea
                          placeholder="Written review(optional)"
                          className="border border-gray-200 w-full bg-gray-50 hover:border-gray-200 focus:bg-gray-200 hover:bg-gray-200 focus:border-gray-200 rounded-md p-2 text-xs resize-none h-18"
                          maxLength={100}
                          onChange={(e) => setReview(e.target.value)}
                        />
                        <div className="flex gap-2">
                          <button
                            type="button"
                            className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                            onClick={(e) => setOpen(!open)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="p-1 pl-2 pr-2 border border-gray-200 rounded-md text-stone-800 bg-gray-200 text-xs"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>
          )}
        </>
      ) : (
        ''
      )}

      <div className="flex flex-col max-w-2xl mx-auto border border-gray-200 rounded-md bg-white mt-2">
        <div className="w-full flex flex-col p-4">
          <div className="flex flex-col w-full">
            <div className="flex justify-between items-center">
              <div className="flex flex-col">
                <p className="text-stone-600 text-xs">Order #:</p>
                <p className="text-xs">{orderAndStore?.order?._id}</p>
              </div>

              <div className="">
                <Badge color="success">
                  {orderAndStore?.order?.total > 0 ? 'Paid' : 'Free'}
                </Badge>
              </div>
            </div>
            <div className="w-full h-72 flex justify-end mt-1 relative">
              <img
                src={orderAndStore?.order?.item?.coverImages[0]?.url}
                className="rounded-md w-full h-full object-fill"
              />
              {orderAndStore?.order?.discount?.code ? (
                <div className="p-2 pt-1 pb-1 bg-gray-100/70 rounded-md absolute top-0 right-0 mt-2 mr-2">
                  {orderAndStore?.order?.discount?.percent ? (
                    <p className="text-stone-800 text-xs">
                      You saved{' '}
                      <span className="font-bold">
                        {orderAndStore?.order?.discount?.amount}%
                      </span>{' '}
                      using code{' '}
                      <span className="font-bold">
                        {orderAndStore?.order?.discount?.code}
                      </span>
                    </p>
                  ) : (
                    <p className="text-stone-800 text-xs">
                      You saved{' '}
                      <span className="font-bold">
                        ${orderAndStore?.order?.discount?.amount}
                      </span>{' '}
                      using code{' '}
                      <span className="font-bold">
                        {orderAndStore?.order?.discount?.code}
                      </span>
                    </p>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="flex w-full mt-4">
              <div className="w-full flex flex-col items-start gap-2">
                <div className="flex flex-col">
                  <p className="text-stone-600 text-xs">Purchased on:</p>
                  <p className="text-xs text-stone-800">
                    {moment(orderAndStore?.order?.placedOn).format(
                      'MMMM Do, YYYY'
                    )}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="text-stone-600 text-xs">Delivered to:</p>
                  <p className="text-stone-800 text-xs">
                    {orderAndStore?.order?.email}
                  </p>
                </div>

                <div className="w-full flex flex-col">
                  <p className="text-stone-600 text-xs">Purchased from:</p>
                  <Link
                    to={`/${orderAndStore?.order?.item?.pageName}`}
                    target="_blank"
                  >
                    <div className="flex gap-1">
                      {/* <Avatar
                        src={orderAndStore?.order?.item?.userPicture}
                        sx={{ width: 22, height: 22 }}
                      /> */}
                      <p className="text-stone-800 text-xs">
                        fruntt.com/{orderAndStore?.order?.item?.pageName}
                      </p>
                      <ExternalLink size={14} className="text-stone-800" />
                    </div>
                  </Link>
                </div>
                <div className="flex flex-col">
                  <p className="text-stone-600 text-xs">Item:</p>
                  <p className="text-xs text-stone-800">
                    {orderAndStore?.order?.item?.title}
                  </p>
                </div>
              </div>

              <div className="w-full flex flex-col gap-2">
                {version ? (
                  <div className="flex flex-col">
                    <p className="text-stone-600 text-xs">Variation:</p>
                    <div className="p-2 rounded-md border border-gray-200 w-full flex flex-col">
                      <p className="text-xs text-stone-800">{version?.title}</p>
                      <p className="text-xs text-stone-600">
                        {version?.description}
                      </p>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="flex flex-col gap-2 w-full border border-gray-200 bg-gray-50 rounded-md p-2">
                  <div className="flex items-center justify-between w-full">
                    <p className="text-xs text-stone-800">Item:</p>
                    <p className="text-xs text-stone-800">
                      {orderAndStore?.order?.currency?.symbol}
                      {itemPrice?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>

                  {orderAndStore?.order?.discount?.code ? (
                    <div className="flex items-center justify-between w-full">
                      <p className="text-xs text-stone-800">Discount:</p>
                      <p className="text-xs text-stone-800">
                        -{orderAndStore?.order?.currency?.symbol}
                        {discountAmount?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  ) : (
                    ''
                  )}

                  {orderAndStore?.order?.total > 0 ? (
                    <div className="flex items-center justify-between w-full">
                      <p className="text-xs text-stone-800">
                        {orderAndStore?.order?.tax > 0 ? (
                          <>
                            {orderAndStore?.order?.taxType === 'vat'
                              ? 'VAT:'
                              : 'Tax:'}
                          </>
                        ) : (
                          'Tax/VAT:'
                        )}
                      </p>
                      <p className="text-xs text-stone-800">
                        $
                        {orderTax?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </p>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="flex items-center justify-between w-full">
                    <p className="text-xs text-stone-800 font-bold">Total:</p>
                    <p className="text-xs text-stone-800">
                      {orderAndStore?.order?.item?.currency?.symbol}
                      {orderAndStore?.order?.total?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex gap-2 mt-2">
              {hasInfo ? (
                <>
                  <a
                    href={orderAndStore?.order?.item?.duplicationLink}
                    target="_blank"
                    className="bg-gray-200 w-10/12 text-xs rounded-md flex items-center justify-center text-stone-800 h-10"
                    onClick={markViewed}
                  >
                    {orderAndStore?.order?.item?.platform === 'Notion'
                      ? 'Open in Notion'
                      : 'Open in Framer'}
                  </a>
                  <button
                    type="button"
                    onClick={(e) => setOpenMore(!openMore)}
                    className="bg-gray-200 rounded-md h-10 text-stone-800 w-2/12 flex items-center justify-center"
                  >
                    {openMore ? (
                      <ChevronUp size={14} />
                    ) : (
                      <ChevronDown size={14} />
                    )}
                  </button>
                </>
              ) : (
                <a
                  href={orderAndStore?.order?.item?.duplicationLink}
                  target="_blank"
                  className="bg-gray-200 w-full text-xs rounded-md flex items-center justify-center text-stone-800 h-10"
                  onClick={markViewed}
                >
                  {orderAndStore?.order?.item?.platform === 'Notion'
                    ? 'Open in Notion'
                    : 'Open in Framer'}
                </a>
              )}
            </div>
          </div>
        </div>
        {openMore && (
          <ReactQuill
            value={orderAndStore?.order?.item?.info}
            readOnly={true}
            theme={'bubble'}
          />
        )}
      </div>
    </div>
  );
};

export default DesktopDownload;
