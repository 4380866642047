import React, { useState, useEffect } from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';
import Cookies from 'js-cookie';
import { useGetCustomersQuery } from '../../../api/customersApiSlice';
import DesktopCustomers from './DesktopCustomers';
import { isMobile } from 'react-device-detect';
import MobileCustomers from './MobileCustomers';
import SideNavbar from '../../../components/SideNavbar';
import { Spinner } from 'flowbite-react';

const Customers = () => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const {
    data: customers,
    isLoading,
    isSuccess,
    isFetching,
    refetch,
  } = useGetCustomersQuery({ storeId: currentUser?.store?._id });

  useEffect(() => {
    refetch();
  }, []);

  let content;

  if (isLoading || isFetching) {
    content = (
      <div
        className="flex items-center justify-center"
        style={{ height: '600px' }}
      >
        <Spinner />
      </div>
    );
  } else if (isSuccess) {
    content = isMobile ? (
      <MobileCustomers customers={customers} />
    ) : (
      <DesktopCustomers customers={customers} />
    );
  }

  const styles = isMobile
    ? 'h-fit w-full p-2 bg-gray-50'
    : 'h-screen w-full bg-white mx-auto ml-2';

  return (
    <>
      <Navbar />
      <div className="flex mx-auto max-w-6xl">
        <SideNavbar />
        <div className={styles}>{content}</div>
      </div>
      <Footer />
    </>
  );
};

export default Customers;
